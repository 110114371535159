.App {
  text-align: center;

  /* Делаем бекграунд нашей подложки */
  /* width: 100%;
  position: absolute; */
  /* -1 у фото HERO  */
  /* z-index: -2;  */
  /* background-color:#EFF1F4; */
  /* background-color: white; */

  /* background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(../src/img/footer-4x.png); */
}


.App::before {
  content: '';
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  z-index: -2; 
  background-color: white;
  /* background-color: #EFF1F4; */
  /* background-color: #3c4e5b; */
  /* background-color: #3a4b58;
  background-color: #425564; */

}


.App-header {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding-left: 195px;
  padding-right: 195px; */

  /* font-size: calc(10px + 2vmin); */
  color: white;
  border-bottom-style: solid;
  border-bottom-width: 1px;

}

.App-hero,
.App-navigation,
.App-Message {

  /* background-color: #e8fdff; */
  /* background-color:#526f75; */

  display: flex;
  justify-content: center;
  /* z-index: 1; */
}


.App-Footer {
  background-color: #000000;
  /* color: white;  */
  /* 1440 - размер соответствует картинке */
  max-width: 1420px;
  margin-left: auto;
  margin-right: auto;

}


.Banner_desktop {
  width: 100%;
  /* Размер оригинала каритнки 1420х580 */
  height: 580px;

  position: absolute;
  top: 0px;
  z-index: -1;

  /* background-color:#526f75; */

  background-repeat: no-repeat;
  background-position: center;
  /* background-size: cover; */
  /* background-size: contain; */
  background-size: fill;

  /* background-image: linear-gradient(to right, rgba(47, 48, 58, 0.4), rgba(47, 48, 58, 0.4)),
    url(../img/mobile-img-480@1x.jpg); !!!!!!!!! */
    background-image: url(../src/img/hero-1x-1400x580.png);
}
.Banner_tablet {
  width: 100%;
  /* Размер оригинала каритнки 1066x436 */
  height: 436px;

  position: absolute;
  top: 0px;
  z-index: -1;

  background-repeat: no-repeat;
  background-position: center;
  background-size: fill;


  /* background-image: linear-gradient(to right, rgba(47, 48, 58, 0.4), rgba(47, 48, 58, 0.4)),
    url(../img/mobile-img-480@1x.jpg); !!!!!!!!! */
    background-image: url(../src/img/hero-0.75x-1066x436.png);
}

.Banner_mobile {
  width: 100%;
  /* Размер оригинала каритнки 711x290 */
  height: 290px;

  position: absolute;
  top: 0px;
  z-index: -1;

  background-repeat: no-repeat;
  background-position: center;
  background-size: fill;


  /* background-image: linear-gradient(to right, rgba(47, 48, 58, 0.4), rgba(47, 48, 58, 0.4)),
    url(../img/mobile-img-480@1x.jpg); !!!!!!!!! */
    background-image: url(../src/img/hero-0.5x-711x290.png);
}

.Banner_desktop__footer {
  /* 1420 - размер взят из ширины картинки Хедера (немного оеруглен) */
  max-width: 1420px;
  /* Размер оригинала каритнки 1440х369 */
  height: 369px;
  margin-left: auto;
  margin-right: auto;

  /* position: absolute;
  top: 0px;
  z-index: -1; */

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* background-size: contain; */
  /* background-size: fill; */

  /* background-image: linear-gradient(to right, rgba(47, 48, 58, 0.4), rgba(47, 48, 58, 0.4)),
    url(../img/mobile-img-480@1x.jpg); !!!!!!!!! */
  background-image: url(../src/img/footer-1x-1440x369.png);

  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #FFFFFF;
}

.Banner_tablet__footer {
  /* 1065 - размер взят из ширины картинки Хедера (немного оеруглен) */
  max-width: 1065px;
  /* Размер оригинала каритнки 1081х278 */
  /* height: 278px; */
  height: 260px;
  margin-left: auto;
  margin-right: auto;

  /* position: absolute;
  top: 0px;
  z-index: -1; */

  background-repeat: no-repeat;
  background-position: center;
  /* background-size: cover; */
  /* background-size: contain; */
  background-size: fill;

  /* background-image: linear-gradient(to right, rgba(47, 48, 58, 0.4), rgba(47, 48, 58, 0.4)),
    url(../img/mobile-img-480@1x.jpg); !!!!!!!!! */
    background-image: url(../src/img/footer-0.75x-1081x278.png);

    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: #FFFFFF;
    /* background-color: red; */
}

.Banner_mobile__footer {
  /* 709 - размер взят из ширины картинки Хедера (немного оеруглен) */
  max-width: 709px;
  /* Размер оригинала каритнки 721х185 */
  height: 177px;
  /* height: 100%; */
  /* height: auto; */
  margin-left: auto;
  margin-right: auto;

  /* position: absolute;
  top: 0px;
  z-index: -1; */

  background-repeat: no-repeat;
  background-position: center;
  /* background-size: cover; */
  /* background-size: contain; */
  background-size: fill;

  /* background-image: linear-gradient(to right, rgba(47, 48, 58, 0.4), rgba(47, 48, 58, 0.4)),
    url(../img/mobile-img-480@1x.jpg); !!!!!!!!! */
    background-image: url(../src/img/footer-0.5x-721x185.png);

    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: #FFFFFF;
    /* background-color: red; */
}

/* .section_Consrtuction,
.section_Design,
.section_Development,
.section_Company,
.section_News,
.section_Home,
.section_User {
  background-color: #e8fdff;
} */

.container {
  width: 1200px;
  /* padding-left: 15px;
  padding-right: 15px; */
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
}


.container_sidebar {
  width: 350px;
}


.flexbox {
  display: flex;
  justify-content: space-evenly;
}

.text_color {
  color: #282c34;
}


.title_color {
  color: #282c34;
}

.text_color_hero {
  color: #526f75;
}

/* Убрали дефолтные настройки линков */
.link {
  /* color: white;
  width: 100px; */
  text-decoration: none;
  margin: 0px;
}


img {
  display: block;
  max-width: 100%;
  height: auto;
}

h1,
h2 {
  margin: 0;
}

ul {
  padding: 0;
  margin: 0;
}
