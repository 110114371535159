
/* Старое------------------------------------------------ */
li {
  margin-bottom: 5px;
}

/* .box {
  align-items: center;
  border-bottom-color: white;
  border-bottom-style: solid;
  border-bottom-width: 1px;
} */

/* .flexbox:after {
  content: 'last updated: 2021.03.27';
  text-transform: none;
  font-size: 0.7rem;
  display: block;
  margin-left: 0.4rem;
  padding-bottom: 8px;
} */

.title {
  text-transform: uppercase;
}

.list {
  text-align: left;
  /* margin-bottom: 10px; */
  margin-left: 20px;
  opacity: 0.5;
}

.text {
  font-size: 14px;
}

.textRights {
  white-space: pre-wrap;
  text-transform: uppercase;
  opacity: 0.5;
  letter-spacing: 0.1em;
}

.box_button {
  margin-bottom: 10px;
}
