
.flexbox_nav {
  display: flex;
  justify-content: space-evenly;
}

/* .link {
  text-decoration: none;
  margin: 0px;
} */



/* ------------------------------Старое--------------- */

.title {
  font-size: 16px;
  text-transform: uppercase;
  color: #526f75;
  margin-top: 10px;
  margin-bottom: 30px;
}

.button,
.button_active {
  font-size: 16px;
  border-radius: 17px;
  padding: 8px 21px;
  font-weight: 700;
  border: 1px solid #5e6671;
  cursor: pointer;
  margin-top: 10px;
}


.button {
  color: #5e6671;
  background-color: #e8fdff;
}

.button_active {
  background: #282c34;
  color: #2196f3;
}

.button:hover,
.button:focus {
  background: #282c34;
  color: #2196f3;
}

.button:disabled:hover,
.button:disabled:focus {
  color: #5e6671;
  background-color: inherit;
  border-color: #5e6671;
  opacity: 0.5;
  cursor: default;
}
