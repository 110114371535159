
/* ---------------------Старое---------------- */
.flexbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.link {
  /* color: white;
  width: 100px; */
  text-decoration: none;
  margin: 0px;
}

/* .link:hover,
.link:focus,
.link__mobile:hover,
.link__mobile:focus {
  color: #2196f3;
} */
